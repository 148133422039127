import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { EnterpriseContext } from "./EnterpriseContext";
import Breadcrumbs from "../../web/components/Breadcrumbs";
import VTCMenu from "../../web/components/VTCMenu";
import CustomBadge from "../ui/CustomBadge";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";

export default function Tags({ container }) {
  const [tags, setTags] = useState([]);
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);
  const es = new EnterpriseService();
  const [currentTag, setCurrentTag] = useState(null);
  const [showTagsModal, toggleTagsModal] = useState(false);
  const [discordRoles, setDiscordRoles] = useState(null);
  const [iconSearchText, setIconSearchText] = useState("");
  const [icons, setIcons] = useState([]);

  const company = globalContext.company;
  const member = globalContext.member;

  useEffect(() => {
    getTags();
    getDiscordRoles();
  }, []);

  const getTags = async () => {
    if (es.can(member, "members.manage_roles")) {
      const result = await es.getCompanyTags(company.id);

      if (!result.message) setTags(result);
    }
  };

  const getDiscordRoles = async () => {
    try {
      const result = await es.getCompanyDiscordServerRoles(company);

      if (result.success) {
        setDiscordRoles(result.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!showTagsModal) {
      setCurrentTag(null);
    }
  }, [showTagsModal]);

  useEffect(() => {
    if (currentTag != null) {
      toggleTagsModal(true);
    }
  }, [currentTag]);

  useEffect(() => {
    searchIcons(iconSearchText);
  }, [iconSearchText]);

  const searchIcons = (text) => {
    setIcons(
      Object.keys(fas)
        .filter((key) => fas[key].iconName.search(text) > -1)
        .slice(0, 30)
        .map((key) => fas[key].iconName)
    );
  };

  const saveTag = async () => {
    let result;

    if (!currentTag.id) {
      result = await es.createCompanyTag(company.id, currentTag);
    } else {
      result = await es.updateCompanyTag(company.id, currentTag);
    }

    if (result.success) {
      toast.success(container.localeManager.strings.tagSaved);
      toggleTagsModal(false);
      getTags();
    } else {
      toast.error(result.message);
    }
  };

  const deleteTag = async (tag) => {
    const result = await es.deleteCompanyTag(company.id, tag.id);
    if (result.success) {
      toast.success(container.localeManager.strings.tagDeleted);
      getTags();
    } else {
      toast.error(result.message);
    }
  };

  return (
    <Row noGutters>
      <Col sm="auto">
        <VTCMenu active={"/company/tags"} container={container}></VTCMenu>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <Breadcrumbs
              breadcrumbs={[
                {
                  to: "/vtchub",
                  title: container.localeManager.strings.vtcHub,
                },
                {
                  to: "/company/members",
                  title: container.localeManager.strings.members,
                },
                {
                  title: container.localeManager.strings.tags,
                },
              ]}
            ></Breadcrumbs>
            <h5 className="d-flex align-items-center justify-content-between w-100 mb-5">
              <div>{container.localeManager.strings.tags}</div>
              {es.can(member, "members.manage_roles") && (
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => setCurrentTag({})}
                >
                  <FontAwesomeIcon icon="add"></FontAwesomeIcon> Create Tag
                </Button>
              )}
            </h5>
            <Table
              responsive
              striped
              size="sm"
              className="table-row-dashed table-row-gray-300 align-middle gs-0"
              hover
            >
              <thead>
                <tr className="fw-bolder text-gray-700 bg-light">
                  <th className="ps-2">
                    {container.localeManager.strings.name}
                  </th>
                  <th></th>
                </tr>
              </thead>
              {tags.map((m) => {
                return (
                  <tr>
                    <td className="py-2">
                      <CustomBadge style={{ backgroundColor: m.color }}>
                        {m.icon ? (
                          <FontAwesomeIcon icon={m.icon}></FontAwesomeIcon>
                        ) : (
                          ""
                        )}
                        {m.name}
                      </CustomBadge>
                    </td>
                    <td className="text-end">
                      <Button
                        title={container.localeManager.strings.edit}
                        onClick={() => setCurrentTag(m)}
                        className="btn-icon w-30px h-30px ms-auto"
                        size="sm"
                        color="active-light-primary"
                      >
                        <FontAwesomeIcon
                          icon="pencil-alt"
                          className="me-0"
                        ></FontAwesomeIcon>
                      </Button>
                      <Button
                        title={container.localeManager.strings.delete}
                        className="btn-icon w-30px h-30px ms-auto"
                        onClick={() => deleteTag(m)}
                        size="sm"
                        color="active-light-danger"
                      >
                        <FontAwesomeIcon
                          icon="trash-alt"
                          className="me-0"
                        ></FontAwesomeIcon>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </Table>
            {showTagsModal && (
              <Modal
                size="lg"
                className="big-modal"
                wrapClassName="big-modal-wrap"
                modalClassName="big-modal-container"
                contentClassName="big-modal-content"
                isOpen={showTagsModal}
                toggle={() => toggleTagsModal(false)}
              >
                <ModalHeader
                  toggle={() => toggleTagsModal(false)}
                  close={
                    <Button
                      color="active-light-primary"
                      className="btn-icon btn-sm w-30px h-30px ms-auto"
                      onClick={() => toggleTagsModal(false)}
                    >
                      <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                    </Button>
                  }
                >
                  {container.localeManager.strings.tags}
                </ModalHeader>
                <ModalBody>
                  {currentTag != null && (
                    <div>
                      <FormGroup>
                        <Label>{container.localeManager.strings.name}</Label>
                        <Input
                          value={currentTag.name}
                          type="text"
                          onChange={(e) =>
                            setCurrentTag({
                              ...currentTag,
                              name: e.target.value,
                            })
                          }
                        ></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label>{container.localeManager.strings.color}</Label>
                        <Input
                          value={currentTag.color}
                          type="color"
                          onChange={(e) =>
                            setCurrentTag({
                              ...currentTag,
                              color: e.target.value,
                            })
                          }
                        ></Input>
                      </FormGroup>
                      {discordRoles &&
                        discordRoles.bot_in_server &&
                        discordRoles.roles && (
                          <FormGroup row>
                            <Col>
                              <Label>Discord Role</Label>
                              <Input
                                type="select"
                                value={currentTag.discord_role_id}
                                onChange={(e) =>
                                  setCurrentTag({
                                    ...currentTag,
                                    discord_role_id: e.target.value,
                                  })
                                }
                              >
                                <option value={null}></option>
                                {discordRoles.roles.map((m) => {
                                  return <option value={m.id}>{m.name}</option>;
                                })}
                              </Input>
                            </Col>
                            <Col></Col>
                          </FormGroup>
                        )}
                      <FormGroup>
                        <Label>{container.localeManager.strings.icon}</Label>
                        {currentTag.icon != null && (
                          <div className="mt-3">
                            <FontAwesomeIcon
                              size="3x"
                              icon={currentTag.icon}
                            ></FontAwesomeIcon>
                          </div>
                        )}
                        <div className="mt-2">
                          <Input
                            placeholder={
                              container.localeManager.strings.browseIcons
                            }
                            value={iconSearchText}
                            onChange={(e) => setIconSearchText(e.target.value)}
                          ></Input>
                          <div className="mt-3">
                            {icons.map((icon) => {
                              return (
                                <span className="border rounded p-2 me-2 mb-2 d-inline-block">
                                  <FontAwesomeIcon
                                    className="me-0"
                                    size="2x"
                                    onClick={() =>
                                      setCurrentTag({
                                        ...currentTag,
                                        icon: icon,
                                      })
                                    }
                                    icon={icon}
                                  ></FontAwesomeIcon>
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup className="mt-3">
                        <Button color="primary" size="sm" onClick={saveTag}>
                          {container.localeManager.strings.save}
                        </Button>
                      </FormGroup>
                    </div>
                  )}
                </ModalBody>
              </Modal>
            )}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
