import { useContext, useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import JobsTable from "./JobsTable";
import { EnterpriseContext } from "./EnterpriseContext";
import EnterpriseNavBar from "./EnterpriseNavBar";
import Paginator from "../ui/Paginator";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";
import { useParams } from "react-router";
import OrderByButtons from "../ui/OrderByButtons";
import TooltipIcon from "../ui/TooltipIcon";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";
import Breadcrumbs from "../../web/components/Breadcrumbs";

const Market = ({ container }) => {
  const [loading, setLoading] = useState(false);
  const es = new EnterpriseService();
  const [marketData, setMarketData] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    difference: "",
    sortingField: "",
    sortingDirection: "",
  });

  const { game_id } = useParams();

  useEffect(() => {
    truckyService.setDocumentTitle("Global Market");
  }, []);

  useEffect(() => {
    getMarket();
  }, [game_id]);

  useEffect(() => {
    getMarket();
  }, [filters.sortingDirection, filters.sortingField]);

  const getMarket = async () => {
    setLoading(true);
    const result = await es.getMarket(game_id, filters);
    setMarketData(result);
    setLoading(false);
  };

  const getDifferenceIcon = (cargo) => {
    var icon;

    if (cargo.difference < 0) {
      icon = (
        <TooltipIcon
          icon="angle-double-down"
          text={`${cargo.difference}%`}
          className="text-danger"
        ></TooltipIcon>
      );
    } else if (cargo.difference > 0) {
      icon = (
        <TooltipIcon
          icon="angle-double-up"
          text={`${cargo.difference}%`}
          className="text-success"
        ></TooltipIcon>
      );
    } else {
      icon = <FontAwesomeIcon icon="equals"></FontAwesomeIcon>;
    }
    return icon;
  };

  const getCargoSpecs = (cargo) => {
    const icons = [];

    if (cargo.is_fragile) {
      icons.push(
        <TooltipIcon
          text={container.localeManager.strings.fragile}
          icon="wine-glass-alt"
        ></TooltipIcon>
      );
    }

    if (cargo.valueable) {
      icons.push(
        <TooltipIcon
          text={container.localeManager.strings.valuable}
          icon="money-bill-alt"
        ></TooltipIcon>
      );
    }

    if (cargo.overweight) {
      icons.push(
        <TooltipIcon
          text={container.localeManager.strings.overweight}
          icon="weight-hanging"
        ></TooltipIcon>
      );
    }

    if (cargo.adr_class > 0) {
      icons.push(
        <TooltipIcon
          text={container.localeManager.strings.adr}
          icon="fire-alt"
        ></TooltipIcon>
      );
    }

    return icons;
  };

  const changeSortingField = ({ field, direction }) => {
    setFilters({
      ...filters,
      sortingField: field,
      sortingDirection: direction,
    });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu active={"/market"} container={container}></VTCMenu>

        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <Breadcrumbs
                    breadcrumbs={[
                      {
                        to: "/vtchub",
                        title: container.localeManager.strings.vtcHub,
                      },
                      {
                        title: container.localeManager.strings.globalMarket,
                      },
                    ]}
                  ></Breadcrumbs>
                  <h5 className="d-inline-block w-100 mb-3">
                    {container.localeManager.strings.globalMarket}
                  </h5>
                  <FormGroup row>
                    <Col sm="auto">
                      <Label>{container.localeManager.strings.name}</Label>
                      <Input
                        value={filters.name}
                        onChange={(e) =>
                          setFilters({ ...filters, name: e.target.value })
                        }
                        type="text"
                      ></Input>
                    </Col>
                    <Col sm="auto">
                      <Label>{container.localeManager.strings.variation}</Label>
                      <Input
                        type="select"
                        value={filters.difference}
                        onChange={(e) =>
                          setFilters({ ...filters, difference: e.target.value })
                        }
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        <option value="positive">
                          {container.localeManager.strings.positive}
                        </option>
                        <option value="no">
                          {container.localeManager.strings.notChanged}
                        </option>
                        <option value="negative">
                          {container.localeManager.strings.negative}
                        </option>
                      </Input>
                    </Col>
                    <Col sm="auto" className="d-flex align-items-end">
                      <Button size="sm" onClick={getMarket} color="primary">
                        <FontAwesomeIcon icon="search"></FontAwesomeIcon>
                        {container.localeManager.strings.search}
                      </Button>
                    </Col>
                  </FormGroup>
                  <div className="text-gray-600 my-3">
                    <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                    {container.localeManager.strings.pricePerKmInfoText}
                  </div>
                  {!loading && marketData.length > 0 && (
                    <Table
                      responsive
                      striped
                      size="sm"
                      className="table-row-dashed table-row-gray-300 align-middle gs-0"
                      hover
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-700 bg-light">
                          <th scope="col" className="ps-4">
                            {container.localeManager.strings.cargo}
                            <OrderByButtons
                              container={container}
                              currentSortingDirection={filters.sortingDirection}
                              currentSortingField={filters.sortingField}
                              field="name"
                              onClick={changeSortingField}
                            ></OrderByButtons>
                          </th>
                          <th scope="col"></th>
                          <th scope="col">
                            {container.localeManager.strings.pricePerKm}
                            <OrderByButtons
                              container={container}
                              currentSortingDirection={filters.sortingDirection}
                              currentSortingField={filters.sortingField}
                              field="price_per_km_with_market_change"
                              onClick={changeSortingField}
                            ></OrderByButtons>
                          </th>
                          <th scope="col" className="text-end">
                            {container.localeManager.strings.demand}
                            <OrderByButtons
                              container={container}
                              currentSortingDirection={filters.sortingDirection}
                              currentSortingField={filters.sortingField}
                              field="market_demand"
                              onClick={changeSortingField}
                            ></OrderByButtons>
                          </th>
                          <th scope="col" className="text-center">
                            {container.localeManager.strings.difference}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketData.map((m) => {
                          return (
                            <tr>
                              <td>{m.name}</td>
                              <td>{getCargoSpecs(m)}</td>
                              <td>
                                {m.avg_job_income != null && (
                                  <TooltipIcon
                                    id={`cargo_${m.id}`}
                                    icon="info-circle"
                                    className="form-text d-inline me-3"
                                    text={
                                      <div className="text-start">
                                        {
                                          container.localeManager.strings
                                            .averageMass
                                        }
                                        : {m.avg_cargo_mass_t} t
                                        <br />
                                        500 km:{" "}
                                        {container.localeManager
                                          .numeral(m.avg_job_income["500"])
                                          .format()}{" "}
                                        T¢
                                        <br />
                                        1000 km:{" "}
                                        {container.localeManager
                                          .numeral(m.avg_job_income["1000"])
                                          .format()}{" "}
                                        T¢
                                        <br />
                                        2000 km:{" "}
                                        {container.localeManager
                                          .numeral(m.avg_job_income["2000"])
                                          .format()}{" "}
                                        T¢
                                      </div>
                                    }
                                  ></TooltipIcon>
                                )}
                                {m.price_per_km_with_market_change} T¢
                              </td>
                              <td className="text-end">
                                {m.market_demand > 0
                                  ? `+${m.market_demand}`
                                  : m.market_demand}
                                %
                              </td>
                              <td className="text-center">
                                {getDifferenceIcon(m)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips
                tipKey={tipsList.KEYS.ENTERPRISE_MARKET}
                localeManager={container.localeManager}
              ></Tips>
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default Market;
