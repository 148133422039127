import { useContext, useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
  UncontrolledButtonDropdown,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import LoadingButton from "../ui/LoadingButton";
import { confirmWrapper } from "../ui/askConfirm";
import Loader from "../ui/Loader";
import { EnterpriseContext } from "./EnterpriseContext";
import LoadingOverlay from "react-loading-overlay";
import EnterpriseNavBar from "./EnterpriseNavBar";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";
import ButtonDropdownMenu from "../ui/ButtonDropdownMenu";
import { Link, useNavigate } from "react-router-dom";
import MaintenanceAlerts from "./MaintenanceAlerts";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";
import Breadcrumbs from "../../web/components/Breadcrumbs";

const GarageManagement = ({ eventBus, container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const company = globalContext.company;
  const member = globalContext.member;

  const es = new EnterpriseService();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [garages, setGarages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentGarage, setCurrentGarage] = useState({ game_id: null });
  const [currentUpgradeMenuOpen, setCurrentUpgradeMenuOpen] = useState(0);
  const [newPlaces, setNewPlaces] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    truckyService.setDocumentTitle("Garage Management");

    getGarages();
  }, []);

  const getGarages = async () => {
    setLoadingData(true);
    const result = await es.getGarages(company);
    setGarages(result);
    setLoadingData(false);
  };

  const searchCities = async (inputValue) => {
    if (inputValue.length > 3) {
      const result = await es.searchCities(inputValue);
      return result;
    }
  };

  const openEditGarageDialog = () => {
    setIsOpen(true);
  };

  const save = async () => {
    if (
      await confirmWrapper(container.localeManager.strings.garageConf, {
        container: container,
      })
    ) {
      const result = await es.buyNewGarage(company, currentGarage);
      if (result.success) {
        toast.success(container.localeManager.strings.deal);
        getGarages();
        setIsOpen(false);
      } else {
        toast.error(result.message);
      }
    }
  };

  const edit = (g) => {
    setCurrentGarage(g);
    setIsOpen(true);
  };

  const deleteGarage = async (garage) => {
    if (
      await confirmWrapper(container.localeManager.strings.garageSell, {
        container: container,
      })
    ) {
      setLoadingData(true);
      const result = await es.deleteGarage(company, garage);
      if (result.success) {
        toast.success(container.localeManager.strings.garageSold);
        await getGarages();
      } else {
        toast.error(result.message);
      }
    }
    setLoadingData(false);
  };

  const upgradeGarage = async (garage) => {
    if (
      await confirmWrapper(container.localeManager.strings.garageUpgrade, {
        container: container,
      })
    ) {
      setButtonIsLoading(true);
      const result = await es.upgradeGarage(company, garage, newPlaces);
      if (result.success) {
        toast.success(container.localeManager.strings.garageUpgraded);
        setCurrentUpgradeMenuOpen(0);
        getGarages();
      } else {
        toast.error(result.message);
      }
      setButtonIsLoading(false);
    }
  };

  return (
    <LoadingOverlay
      active={loadingData}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu container={container}></VTCMenu>
        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <Breadcrumbs
                    breadcrumbs={[
                      {
                        to: "/vtchub",
                        title: container.localeManager.strings.vtcHub,
                      },
                      {
                        to: "/garages",
                        title: container.localeManager.strings.garages,
                      },
                    ]}
                  ></Breadcrumbs>
                  <h5 className="d-inline-block w-100 mb-3">
                    {container.localeManager.strings.garageManagement}
                    {!loadingData && es.can(member, "garages.buy") && (
                      <div className="float-end">
                        <Button
                          size="sm"
                          onClick={openEditGarageDialog}
                          color="primary"
                        >
                          <FontAwesomeIcon icon="cart-plus" />
                          {container.localeManager.strings.buyANewGarage}
                        </Button>
                      </div>
                    )}
                  </h5>
                  {!loadingData && garages.length == 0 && (
                    <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 mb-3">
                      {container.localeManager.strings.noGaragesEnterprise}
                    </div>
                  )}
                  {!loadingData && garages.length > 0 && (
                    <div>
                      <Table
                        responsive
                        striped
                        size="sm"
                        className="table-row-dashed table-row-gray-300 align-middle gs-0"
                        hover
                      >
                        <thead>
                          <tr className="fw-bolder text-gray-700 bg-light">
                            <th scope="col" className="ps-4">
                              #
                            </th>
                            <th scope="col">
                              {container.localeManager.strings.city}
                            </th>
                            <th scope="col">
                              {container.localeManager.strings.garagePlaces}
                            </th>
                            <th scope="col">
                              {container.localeManager.strings.price}
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {garages.map((g) => {
                            return (
                              <tr>
                                <td className="ps-1">{g.id}</td>
                                <td>
                                  <Link to={`/garage/${g.id}`}>
                                    {g.city.name}
                                  </Link>
                                </td>
                                <td>
                                  {g.vehicles_count} / {g.max_vehicles}
                                </td>
                                <td>
                                  {container.localeManager
                                    .numeral(g.price)
                                    .format("0,0")}{" "}
                                  {g.currency}
                                </td>
                                <td className="text-end">
                                  <Button
                                    onClick={() => navigate(`/garage/${g.id}`)}
                                    className="btn-icon w-30px h-30px ms-auto"
                                    size="sm"
                                    color="active-light-primary"
                                  >
                                    <FontAwesomeIcon
                                      icon="pencil-alt"
                                      className="me-0"
                                    />
                                  </Button>
                                  {es.can(member, "garages.sell") && (
                                    <Button
                                      title={
                                        container.localeManager.strings.sell
                                      }
                                      onClick={() => deleteGarage(g)}
                                      className="btn-icon w-30px h-30px ms-auto"
                                      size="sm"
                                      color="active-light-danger"
                                    >
                                      <FontAwesomeIcon
                                        icon="trash-alt"
                                        className="me-0"
                                      />
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                  <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
                    <ModalHeader
                      toggle={() => setIsOpen(false)}
                      close={
                        <Button
                          color="active-light-primary"
                          className="btn-icon btn-sm w-30px h-30px ms-auto"
                          onClick={() => setIsOpen(false)}
                        >
                          <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                        </Button>
                      }
                    >
                      {container.localeManager.strings.editGarage}
                    </ModalHeader>
                    <ModalBody>
                      <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-3">
                        {container.localeManager.strings.garageInfo}
                      </div>
                      <FormGroup row>
                        <Col sm={6}>
                          <Label>{container.localeManager.strings.game}</Label>
                          <Input
                            onChange={(e) =>
                              setCurrentGarage({
                                ...currentGarage,
                                game_id: e.target.value,
                              })
                            }
                            type="select"
                          >
                            <option value="">
                              {container.localeManager.strings.selectAGame}
                            </option>
                            <option value="1">Euro Truck Simulator 2</option>
                            <option value="2">American Truck Simulator</option>
                          </Input>
                        </Col>
                        <Col sm={6}>
                          {currentGarage.game_id != null &&
                            currentGarage.game_id != "" && (
                              <>
                                <Label>
                                  {container.localeManager.strings.city}
                                </Label>
                                <AsyncSelect
                                  noOptionsMessage={({ inputValue }) =>
                                    !inputValue
                                      ? container.localeManager.strings
                                          .startTyping
                                      : container.localeManager.strings
                                          .noResults
                                  }
                                  cacheOptions
                                  onChange={(selected) =>
                                    setCurrentGarage({
                                      ...currentGarage,
                                      city_id: selected.id,
                                    })
                                  }
                                  getOptionValue={(m) => m.id}
                                  getOptionLabel={(m) =>
                                    `${m.country.name} - ${m.name}`
                                  }
                                  styles={reactSelectCustomStyles}
                                  loadOptions={searchCities}
                                ></AsyncSelect>
                              </>
                            )}
                        </Col>
                      </FormGroup>
                      <FormGroup className="mt-3">
                        <Button size="sm" onClick={save} color="primary">
                          <FontAwesomeIcon icon="cart-plus" />
                          {container.localeManager.strings.buy}
                        </Button>
                      </FormGroup>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips
                tipKey={tipsList.KEYS.ENTERPRISE_GARAGES}
                localeManager={container.localeManager}
              ></Tips>
              <MaintenanceAlerts
                company={company}
                container={container}
              ></MaintenanceAlerts>
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default GarageManagement;
