import { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import Paginator from "../ui/Paginator";
import CompanyFeedItems from "./CompanyFeedItems";
import { EnterpriseContext } from "./EnterpriseContext";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";
import Breadcrumbs from "../../web/components/Breadcrumbs";

const AllCompanyFeeds = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const [feeds, setFeeds] = useState([]);
  const [parameters, setParameters] = useState({
    paginate: true,
    page: 1,
    perPage: 25,
  });
  const [totalPages, setTotalPages] = useState(0);
  const es = new EnterpriseService();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    truckyService.setDocumentTitle("All Company News");
  }, []);

  useEffect(() => {
    getFeeds();
  }, [parameters.page]);

  const getFeeds = async () => {
    setLoading(true);
    const result = await es.getPublishedFeed(
      globalContext.company.id,
      parameters
    );
    setFeeds(result.data);
    setTotalPages(result.last_page);
    setLoading(false);
  };

  const handlePageClick = (event) => {
    setParameters({ ...parameters, page: event.selected + 1 });
  };

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner={
          <Spinner
            color="primary"
            style={{ width: "4rem", height: "4rem" }}
          ></Spinner>
        }
      >
        <Row noGutters>
          <VTCMenu container={container}></VTCMenu>
          <Col>
            <div className="ps-1">             
              <h5 className="mb-3 ps-1">
                {container.localeManager.strings.newsFeed}
              </h5>
            </div>
            <Paginator
              forcePage={parameters.page - 1}
              totalPages={totalPages}
              handlePageClick={handlePageClick}
            />
            <CompanyFeedItems
              container={container}
              items={feeds}
            ></CompanyFeedItems>
            <div className="mb-3">
              <Paginator
                forcePage={parameters.page - 1}
                totalPages={totalPages}
                handlePageClick={handlePageClick}
              />
            </div>
          </Col>
          {!container.dataService.data.enablePremiumFeature() && (
            <aside className="ad-column-placeholder"></aside>
          )}
        </Row>
      </LoadingOverlay>
    </>
  );
};

export default AllCompanyFeeds;
