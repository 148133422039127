import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Badge, Collapse, NavbarText, Button, Row, Col } from 'reactstrap';
import { EnterpriseContext } from "./EnterpriseContext";
import { useNavigate, useParams } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EnterpriseService from '../../common/services/enterprise-service';
import { MenuComponent } from '../../common/plugins/metronic/components/MenuComponent';
import CustomBadge from '../ui/CustomBadge';
const classnames = require('classnames');

const EnterpriseNavBar = ({ container, selected }) => {

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.bootstrap();
        }, 500);
    });   

    const navigate = useNavigate();
    const es = new EnterpriseService();
    const { globalContext } = useContext(EnterpriseContext);
    let [notifications, setNotifications] = useState([]);

    useEffect(() => {
        if (globalContext.member != null && globalContext.company != null) {
            getMyNotifications();
        }
    }, [globalContext]);

    const getMyNotifications = async () => {
        const result = await es.getMyNotifications(globalContext.member);
        setNotifications(result);
    };

    const readNotification = async (notif) => {
        await es.markNotificationAsRead(notif);
        notifications = notifications.filter(m => m.id != notif.id);
        setNotifications(notifications);
        navigate(notif.data.route);
    }

    return (
        <>
            <Row className='toolbar mb-0'>
                <Col sm={12}>
                    <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
                        <div data-kt-menu-trigger="hover" data-kt-menu-placement="bottom-start" className={classnames({ "menu-item": true, "menu-lg-down-accordion": true, "me-lg-1": true, "active": selected == 'dashboard' })}>
                            <Link to={'/vtchub'} className={classnames({ "menu-link": true, 'active': selected == 'dashboard' })}>
                                <span class="menu-icon">
                                    <FontAwesomeIcon icon="home"></FontAwesomeIcon>
                                </span>
                                <span class="menu-title">{container.localeManager.strings.dashboard}</span>
                            </Link>                            
                        </div>
                        {globalContext.company != null &&
                            <>
                                <div data-kt-menu-trigger="hover" data-kt-menu-placement="bottom-start" className={classnames({ "menu-item": true, "menu-lg-down-accordion": true, "me-lg-1": true, "active": selected == 'members' })}>
                                    <a href="#" className={classnames({ "menu-link": true, 'active': selected == 'members' })}>
                                        <span class="menu-icon">
                                            <FontAwesomeIcon icon="user-cog"></FontAwesomeIcon>
                                        </span>
                                        <span class="menu-title">{container.localeManager.strings.members}</span>
                                        <span class="menu-arrow"></span>
                                    </a>
                                    <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                        <div class="menu-item">
                                            <a onClick={() => navigate('/company/members')} class="menu-link py-3">
                                                <span class="menu-icon">
                                                    <FontAwesomeIcon icon="users"></FontAwesomeIcon>
                                                </span>
                                                <span class="menu-title">{container.localeManager.strings.users}</span>
                                            </a>
                                        </div>
                                        {es.can(globalContext.member, 'members.manage_applications') &&
                                            <div class="menu-item">
                                                <a onClick={() => navigate('/applications')} class="menu-link py-3">
                                                    <span class="menu-icon">
                                                        <FontAwesomeIcon icon="user-plus"></FontAwesomeIcon>
                                                    </span>
                                                    <span class="menu-title">{container.localeManager.strings.applications}</span>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class={classnames({ "menu-item": true, active: selected == "jobs" })}>
                                    <Link to={'/jobs'} className={classnames({ "menu-link": true, 'active': selected == 'jobs' })}>
                                        <span class="menu-icon">
                                            <FontAwesomeIcon icon="suitcase"></FontAwesomeIcon>
                                        </span>
                                        <span class="menu-title">{container.localeManager.strings.jobs}</span>
                                    </Link>
                                </div>
                            </>
                        }
                        {globalContext.company != null && (globalContext.company.company_type == 'realistic' || globalContext.company.company_type == 'both') &&
                            <>
                                <div class={classnames({ "menu-item": true, active: selected == "balance" })}>
                                    <Link to={'/balance'} className={classnames({ "menu-link": true, 'active': selected == 'balance' })}>
                                        <span class="menu-icon">
                                            <FontAwesomeIcon icon="piggy-bank"></FontAwesomeIcon>
                                        </span>
                                        <span class="menu-title">{container.localeManager.strings.balance}</span>
                                    </Link>
                                </div>
                                <div data-kt-menu-trigger="hover" data-kt-menu-placement="bottom-start" className={classnames({ "menu-item": true, "menu-lg-down-accordion": true, "me-lg-1": true, "active": selected == 'fleet' })}>
                                    <a href="#" className={classnames({ "menu-link": true, 'active': selected == 'fleet' })}>
                                        <span class="menu-icon">
                                            <FontAwesomeIcon icon="truck"></FontAwesomeIcon>
                                        </span>
                                        <span class="menu-title">{container.localeManager.strings.fleet}</span>
                                        <span class="menu-arrow"></span>
                                    </a>
                                    <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                        <div class="menu-item">
                                            <a onClick={() => navigate('/garages')} class="menu-link py-3">
                                                <span class="menu-icon">
                                                    <FontAwesomeIcon icon="warehouse"></FontAwesomeIcon>
                                                </span>
                                                <span class="menu-title">{container.localeManager.strings.garages}</span>
                                            </a>
                                        </div>
                                        <div class="menu-item">
                                            <a onClick={() => navigate('/vehicles')} class="menu-link py-3">
                                                <span class="menu-icon">
                                                    <FontAwesomeIcon icon="truck"></FontAwesomeIcon>
                                                </span>
                                                <span class="menu-title">{container.localeManager.strings.vehicles}</span>
                                            </a>
                                        </div>
                                        <div class="menu-item">
                                            <a onClick={() => navigate('/maintenances')} class="menu-link py-3">
                                                <span class="menu-icon">
                                                    <FontAwesomeIcon icon="wrench"></FontAwesomeIcon>
                                                </span>
                                                <span class="menu-title">{container.localeManager.strings.maintenance}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {globalContext.company != null &&
                            <div class={classnames({ "menu-item": true, active: selected == "stats" })}>
                                <Link to={'/stats'} className={classnames({ "menu-link": true, 'active': selected == 'stats' })}>
                                    <span class="menu-icon">
                                        <FontAwesomeIcon icon="table"></FontAwesomeIcon>
                                    </span>
                                    <span class="menu-title">{container.localeManager.strings.stats}</span>
                                </Link>
                            </div>
                        }
                        {globalContext.company != null && (globalContext.company.company_type == 'realistic' || globalContext.company.company_type == 'both') &&
                            <div data-kt-menu-trigger="hover" data-kt-menu-placement="bottom-start" className={classnames({ "menu-item": true, "menu-lg-down-accordion": true, "me-lg-1": true, "active": selected == 'market' })}>
                                <a href="#" className={classnames({ "menu-link": true, 'active': selected == 'market' })}>
                                    <span class="menu-icon">
                                        <FontAwesomeIcon icon="dumpster"></FontAwesomeIcon>
                                    </span>
                                    <span class="menu-title">{container.localeManager.strings.globalMarket}</span>
                                    <span class="menu-arrow"></span>
                                </a>
                                <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                                    <div class="menu-item">
                                        <a onClick={() => navigate('/market/1')} class="menu-link py-3">
                                            <span class="menu-title">{container.localeManager.strings.europe}</span>
                                        </a>
                                    </div>
                                    <div class="menu-item">
                                        <a onClick={() => navigate('/market/2')} class="menu-link py-3">
                                            <span class="menu-title">{container.localeManager.strings.usa}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Col>
                {/*<Col sm={3} className="d-flex justify-content-end">
                    {globalContext.company != null && globalContext.member != null &&
                        <>
                            <div className='d-inline-block'>
                                <div className='d-flex align-items-center ms-1 ms-lg-3'>
                                    <Button size="sm" color='secondary' onClick={() => navigate(`/user/${globalContext.member.id}`)}><FontAwesomeIcon icon="address-card"></FontAwesomeIcon>{container.localeManager.strings.yourCompanyProfile}</Button>
                                </div>
                            </div>

                            <div class="d-inline-block mx-3">
                                <Button disabled={notifications.length == 0} size="sm" {...{ "data-kt-menu-trigger": "hover", "data-kt-menu-attach": "parent", "data-kt-menu-placement": "bottom-end" }} className="h-35px" color="secondary"><FontAwesomeIcon icon="bell"></FontAwesomeIcon><CustomBadge color="primary" className={notifications.length > 0 ? 'blink' : ''}>{notifications.length}</CustomBadge></Button>
                                {notifications.length > 0 &&
                                    <div class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
                                        <h3 class="text-white fw-bold px-9 mt-10 mb-6">{container.localeManager.strings.notifications}</h3>
                                        <div class="scroll-y mh-325px my-5 px-8">
                                            {notifications.map((m) => {
                                                return (
                                                    <div class="d-flex flex-stack py-4">
                                                        <div class="d-flex align-items-center me-2">
                                                            <a onClick={() => readNotification(m)} class="text-gray-800 text-hover-primary fw-bold">{m.data.text}</a>
                                                        </div>
                                                        <span class="badge badge-light fs-8">{container.localeManager.moment(m.created_at).fromNow()}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </Col>
                */}
            </Row>
        </>
    )
};

export default EnterpriseNavBar;