import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { useContext, useState } from "react";
import { useEffect } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import LoadingOverlay from "react-loading-overlay";
import { EnterpriseContext } from "./EnterpriseContext";
import Paginator from "../ui/Paginator";
import Tips from "../Tips";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmWrapper } from "../ui/askConfirm";
import { toast, ToastContainer } from "react-toastify";
import TransactionDetails from "./TransactionDetails";
import { Link } from "react-router-dom";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import truckyService from "../../common/services/trucky-service";
import VTCMenu from "../../web/components/VTCMenu";
import Breadcrumbs from "../../web/components/Breadcrumbs";
const classnames = require("classnames");

const Balance = ({ container }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const company = globalContext.company;
  const member = globalContext.member;

  const es = new EnterpriseService();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const [totalPages, setTotalPages] = useState(0);
  const [stats, setStats] = useState(null);
  const navigate = useNavigate();
  const [showLoanDialog, toggleLoanDialog] = useState(false);
  const [availableLoans, setAvailableLoans] = useState([]);
  const [selectedLoanPlan, setSelectedLoanPlan] = useState(null);
  const [loans, setLoans] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);

  useEffect(() => {
    truckyService.setDocumentTitle("Company Balance");

    getBalanceStats();
    getCompanyLoans();
  }, []);

  const getTransactionDetails = async (id) => {
    const result = await es.getTransactionDetails(company, id);
    setTransactionDetails(result);
  };

  useEffect(() => {
    if (showLoanDialog && availableLoans.length == 0) {
      getAvailableLoans();
    }
  }, [showLoanDialog]);

  const getAvailableLoans = async () => {
    const result = await es.getAvailableLoans();
    setAvailableLoans(result);
  };

  const getCompanyLoans = async () => {
    if (es.can(member, "loans.manage")) {
      const result = await es.getCompanyLoans(company.id);
      if (!result.message) setLoans(result.loans);
    }
  };

  const getBalanceStats = async () => {
    const result = await es.getBalanceStats(company);
    if (!result.message) setStats(result);
  };

  useEffect(() => {
    getTransactions();
  }, [pagination]);

  const getTransactions = async () => {
    setLoading(true);
    const result = await es.getTransactions(company, pagination);
    setTransactions(result.data);
    setTotalPages(result.last_page);
    setLoading(false);
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const getTransactionDescription = (t) => {
    if (t.caused_by != null) {
      if (t.reason == "delivery") {
        return `${t.caused_by.from} - ${t.caused_by.to} (${t.caused_by.cargo})`;
      }

      if (t.reason == "garage") {
        return `${t.caused_by.name}`;
      }

      if (t.reason == "dealer") {
        return `${t.caused_by.name}`;
      }

      if (t.reason == "maintenance") {
        return `${t.caused_by.name}`;
      }
    } else {
      return "";
    }
  };

  const requestLoan = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.areYouSureToRequestALoan,
        { container }
      )
    ) {
      const result = await es.requestLoan(company.id, selectedLoanPlan);
      if (result.success) {
        toggleLoanDialog(false);
        getTransactions();
        getCompanyLoans();
      } else {
        toast.error(result.message);
      }
    }
  };

  const repayLoan = async (id) => {
    const result = await es.repayLoan(company.id, id);
    getBalanceStats();
    getTransactions();
    getCompanyLoans();
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu active={"/balance"} container={container}></VTCMenu>
        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <Breadcrumbs
                    breadcrumbs={[
                      {
                        to: "/vtchub",
                        title: container.localeManager.strings.vtcHub,
                      },
                      {
                        title: container.localeManager.strings.companyBalance,
                      },
                    ]}
                  ></Breadcrumbs>
                  <div className="d-flex align-items-center mb-3 me-3">
                    <div className="flex-grow-1">
                      <h5>{container.localeManager.strings.companyBalance}</h5>
                    </div>
                    {es.can(globalContext.member, "loans.manage") && (
                      <div>
                        <Button
                          size="sm"
                          className="mx-2 mt-3 w-100"
                          color="primary"
                          onClick={() => toggleLoanDialog(true)}
                        >
                          {container.localeManager.strings.requestLoan}
                        </Button>
                      </div>
                    )}
                  </div>
                  <h5></h5>
                  {stats != null && (
                    <div className="grid cols-3 my-3">
                      <div class="bg-light rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                        <div class="fs-2 fw-bolder counted">
                          {container.localeManager
                            .numeral(stats.total)
                            .format("0,0.[00]")}{" "}
                          T¢
                        </div>
                        <div class="fw-bold fs-6 text-gray-600">
                          {container.localeManager.strings.total}
                        </div>
                      </div>
                      <div class="bg-light rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                        <div class="fs-2 fw-bolder counted">
                          {container.localeManager
                            .numeral(stats.today)
                            .format("0,0.[00]")}{" "}
                          T¢
                        </div>
                        <div class="fw-bold fs-6 text-gray-600">
                          {container.localeManager.strings.today}
                        </div>
                      </div>
                      <div class="bg-light rounded min-w-125px py-3 px-4 mb-3 mb-md-0">
                        <div class="fs-2 fw-bolder counted">
                          {container.localeManager
                            .numeral(stats.monthly)
                            .format("0,0.[00]")}{" "}
                          T¢
                        </div>
                        <div class="fw-bold fs-6 text-gray-600">
                          {container.localeManager.strings.thisMonth}
                        </div>
                      </div>
                    </div>
                  )}
                  {loans.length > 0 && (
                    <div className="mt-5 mb-3">
                      <h6>{container.localeManager.strings.currentLoans}</h6>
                      <div className="grid cols-4">
                        {loans.map((m) => {
                          return (
                            <div class="border border-danger border-dashed rounded min-w-125px py-3 px-4 mb-3 mb-3 mb-md-0">
                              <div class="fs-2 fw-bolder counted">
                                {container.localeManager
                                  .numeral(m.requested_value)
                                  .format("0,0.[00]")}{" "}
                                T¢
                              </div>
                              <div class="fw-bold fs-6 text-gray-600">
                                {container.localeManager.strings.formatString(
                                  container.localeManager.strings.remainingDays,
                                  container.localeManager
                                    .numeral(m.remaining_value)
                                    .format("0,0.[00]")
                                )}
                                <br />
                                {container.localeManager.strings.formatString(
                                  container.localeManager.strings
                                    .dailyInstalment,
                                  container.localeManager
                                    .numeral(m.instalment_value)
                                    .format("0,0.[00]")
                                )}
                                <br />
                                {container.localeManager.strings.formatString(
                                  container.localeManager.strings.endsAt,
                                  container.localeManager
                                    .moment(m.pay_until)
                                    .format("L")
                                )}
                              </div>
                              {stats != null &&
                                stats.total > m.remaining_value && (
                                  <Button
                                    color="success"
                                    className="btn-outline btn-active-light-success"
                                    outline
                                    size="sm"
                                    onClick={() => repayLoan(m.id)}
                                  >
                                    {container.localeManager.strings.repay}
                                  </Button>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {!loading && transactions.length > 0 && (
                    <>
                      <Paginator
                        forcePage={pagination.page - 1}
                        totalPages={totalPages}
                        handlePageClick={handlePageClick}
                      />
                      <Row>
                        <Col>
                          <Table
                            responsive
                            striped
                            size="sm"
                            className="table-row-dashed table-row-gray-300 align-middle gs-0 mt-3"
                            hover
                          >
                            <thead>
                              <tr className="fw-bolder text-gray-700 bg-light">
                                <th scope="col" className="ps-4">
                                  {container.localeManager.strings.date}
                                </th>
                                <th scope="col">
                                  {container.localeManager.strings.member}
                                </th>
                                <th scope="col">
                                  {container.localeManager.strings.reason}
                                </th>
                                <th scope="col">
                                  {container.localeManager.strings.details}
                                </th>
                                <th scope="col" className="pe-4">
                                  {container.localeManager.strings.value}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map((m) => {
                                return (
                                  <tr>
                                    <td className="ps-2">
                                      {container.localeManager
                                        .moment(m.created_at)
                                        .format("l")}
                                    </td>
                                    <td>
                                      {m.user != null ? (
                                        <Link to={`/user/${m.user.id}`}>
                                          {m.user.name}
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td>
                                      {
                                        container.localeManager.strings[
                                          m.reason
                                        ]
                                      }
                                      {m.reason == "salaries" &&
                                        m.salaries_details_count > 0 && (
                                          <a
                                            className="ms-3 text-white"
                                            onClick={() =>
                                              getTransactionDetails(m.id)
                                            }
                                          >
                                            <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                                          </a>
                                        )}
                                    </td>
                                    <td>{getTransactionDescription(m)}</td>
                                    <td
                                      className={classnames({
                                        "text-success": m.value > 0,
                                        "text-danger": m.value <= 0,
                                      })}
                                    >
                                      {container.localeManager
                                        .numeral(m.value)
                                        .format("0,0.[00]")}{" "}
                                      {m.currency}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips localeManager={container.localeManager}></Tips>
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
        </Col>
      </Row>
      <Modal
        size="lg"
        isOpen={showLoanDialog}
        toggle={() => toggleLoanDialog(false)}
      >
        <ModalHeader
          toggle={() => toggleLoanDialog(false)}
          close={
            <Button
              color="active-light-primary"
              className="btn-icon btn-sm w-30px h-30px ms-auto"
              onClick={() => toggleLoanDialog(false)}
            >
              <FontAwesomeIcon icon="times"></FontAwesomeIcon>
            </Button>
          }
        >
          {container.localeManager.strings.requestLoan}
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
            {container.localeManager.strings.loanInfo}
          </div>
          <div className="grid cols-2">
            {availableLoans.map((m) => {
              return (
                <Card
                  className={classnames({
                    "mx-0": true,
                    "bg-light": true,
                    "border border-success":
                      selectedLoanPlan != null && selectedLoanPlan.id == m.id,
                  })}
                  onClick={() => setSelectedLoanPlan(m)}
                >
                  <CardBody>
                    <div className="fs-3 points text-center">
                      {container.localeManager
                        .numeral(m.value)
                        .format("0,0.[00]")}{" "}
                      T¢
                    </div>
                    <div className="mt-3">
                      {container.localeManager.strings.formatString(
                        container.localeManager.strings.duratiooin,
                        m.duration
                      )}
                      <br />
                      {container.localeManager.strings.formatString(
                        container.localeManager.strings.rate,
                        m.rate
                      )}
                      <br />
                      {container.localeManager.strings.formatString(
                        container.localeManager.strings.dailyInstalment,
                        container.localeManager
                          .numeral(m.instalment_value)
                          .format("0,0.[00]")
                      )}
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </div>
          {selectedLoanPlan != null && (
            <div className="text-end mt-3">
              <Button onClick={requestLoan} color="primary">
                {container.localeManager.strings.requestLoan}
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
      {transactionDetails != null && (
        <TransactionDetails
          company={company}
          container={container}
          details={transactionDetails}
          onClose={() => setTransactionDetails(null)}
        ></TransactionDetails>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </LoadingOverlay>
  );
};

export default Balance;
