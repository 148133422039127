/* global $ */
/* global overwolf */

import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Alert,
  ButtonGroup,
  Row,
  Spinner,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormText,
  InputGroup,
  InputGroupText,
  CardHeader,
  Table,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import EnterpriseService from "../../common/services/enterprise-service";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "../ui/LoadingButton";
import ErrorBox from "../ui/ErrorBox";
import { confirmWrapper } from "../ui/askConfirm";
import EnterpriseNavBar from "./EnterpriseNavBar";
import { EnterpriseContext } from "./EnterpriseContext";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate, useParams } from "react-router";
import classnames from "classnames";
import MDEditor from "@uiw/react-md-editor";
import truckyService from "../../common/services/trucky-service";
import TransactionDetails from "./TransactionDetails";
import CompanyWebhooks from "./CompanyWebhooks";
import Separator from "../ui/Separator";
import VTCMenu from "../../web/components/VTCMenu";
import config from "../../config";
import Breadcrumbs from "../../web/components/Breadcrumbs";

const EditCompany = ({ container }) => {
  const { globalContext, setGlobalContext } = useContext(EnterpriseContext);

  const navigate = useNavigate();
  const es = new EnterpriseService();
  const [company, setData] = useState({ company_type: "miles" });
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [banner, setBanner] = useState(null);
  const [errors, setErrors] = useState(null);
  const [countries, setCountries] = useState([]);
  const [firstCompanyConfiguration, setFirstCompanyConfiguration] =
    useState(false);
  const [languages, setLanguages] = useState([]);
  const [activeTab, toggleTab] = useState("main");
  const [token, setToken] = useState("");
  const [simulatedSalaries, setSimulatedSalaries] = useState(null);
  const [members, setMembers] = useState([]);
  const [newOwner, setNewOwner] = useState({
    new_owner_id: "",
    new_role_id: "",
  });
  const [roles, setRoles] = useState([]);
  const [guilds, setGuilds] = useState([]);
  const [botIsInserverResult, setBotIsInServerResult] = useState(null);

  useEffect(() => {
    truckyService.setDocumentTitle("Company Settings");

    if (
      globalContext.member.company &&
      globalContext.member.company.id &&
      globalContext.member.company.id > 0
    )
      getCompany();
    else {
      setFirstCompanyConfiguration(true);
    }

    getCountries();
    getLanguages();
  }, []);

  const getCompany = async () => {
    setLoading(true);
    const result = await es.getCompany(globalContext.member.company.id);
    setData(result);

    if (result.company_type == null) {
      setFirstCompanyConfiguration(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab == "transfer") {
      getMembers();
    }

    if (activeTab == "discord" && config.ENABLE_DISCORD_INTEGRATION) {
      getUserGuilds();
    }
  }, [activeTab]);

  const getUserGuilds = async () => {
    try {
      const result = await es.getUserDiscordServers();
      if (result.success) {
        setGuilds(result.guilds);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMembers = async () => {
    if (members.length == 0) {
      setLoading(true);
      const membersResult = await es.getMembers(
        company,
        { perPage: 1000 },
        { sortingField: "name", sortingDirection: "ASC" }
      );
      const rolesResult = await es.getCompanyRoles(company);
      setMembers(
        membersResult.data.filter((m) => m.id != globalContext.member.id)
      );
      setRoles(rolesResult.filter((m) => !m.owner));
      setLoading(false);
    }
  };

  const save = async () => {
    var canContinue = true;

    if (
      (company.company_type == "realistic" || company.company_type == "both") &&
      company.headquarter_city_id == null
    )
      canContinue = false;

    if (canContinue) {
      setLoading(true);

      var _company = null;
      var result = null;
      var creating = true;

      if (company.id) {
        creating = false;
        result = await es.updateCompany(company);
      } else {
        result = await es.createCompany(company);
      }

      if (result.success) {
        _company = result.company;

        if (logo != null) {
          _company = await es.uploadCompanyAsset(_company, "avatar", logo);
        }

        if (banner != null) {
          _company = await es.uploadCompanyAsset(_company, "cover", banner);
        }

        if (creating)
          toast.success(container.localeManager.strings.companyCreated);
        else toast.success(container.localeManager.strings.companyUpdated);

        let newGlobalContext = { company: _company };

        if (creating) {
          newGlobalContext.member = await es.me();
        }

        setLoading(false);
        setData(_company);
        setGlobalContext({ ...globalContext, ...newGlobalContext });

        navigate("/vtchub");
      } else {
        setLoading(false);
        toast.error(result.message);
      }
    } else toast.error(container.localeManager.strings.saveEditIncompleteForm);
  };

  const searchCities = async (inputValue) => {
    if (inputValue.length > 3) {
      const result = await es.searchCities(inputValue);
      return result;
    }
  };

  const onSelectHeadquarter = (selected) => {
    setData({
      ...company,
      headquarter_city_id: selected.id,
      currency: selected.country.currency,
    });
  };

  const closeCompany = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.deleteCompanyWarning,
        { container: container }
      )
    ) {
      setLoading(true);
      await es.deleteCompany(company);
      setGlobalContext({ ...globalContext, company: null });
      setLoading(false);
      navigate("/my/profile");
    }
  };

  const getCountries = async () => {
    setCountries(await es.getCountries());
  };

  const getLanguages = async () => {
    setLanguages(await es.getLanguages());
  };

  const claimToken = async () => {
    const result = await es.claimCompanyToken();
    setToken(result.token);
  };

  const simulateSalaries = async () => {
    setLoading(true);
    const result = await es.getCompanySalariesSimulation(
      company.id,
      company.salary_type,
      company.base_member_salary
    );
    setSimulatedSalaries(result);
    setLoading(false);
  };

  const resetCompany = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.resetCompanyConfirm,
        { container }
      )
    ) {
      setLoading(true);
      const result = await es.resetCompany(company.id);
      toast.success(container.localeManager.strings.operationCompleted);
      setLoading(false);
    }
  };

  const transferOwnership = async () => {
    if (
      await confirmWrapper(
        container.localeManager.strings.transferOwnershipConfirmation,
        { container }
      )
    ) {
      setLoading(true);
      const result = await es.changeCompanyOwner(company, newOwner);
      if (result.success) {
        toast.success(container.localeManager.strings.operationCompleted);
        setGlobalContext({
          company: result.data.company,
          member: result.data.member,
        });
        navigate("/vtchub");
      } else {
        toast.error(result.message);
      }
      setLoading(false);
    }
  };

  const getSaveButton = () => {
    return (
      <FormGroup row>
        <Col>
          <LoadingButton
            size="sm"
            loading={loading}
            color="primary"
            onClick={save}
          >
            {company.id == undefined && (
              <>{container.localeManager.strings.createYourCompany}</>
            )}
            {company.id > 0 && <>{container.localeManager.strings.save}</>}
          </LoadingButton>
        </Col>
      </FormGroup>
    );
  };

  useEffect(() => {
    if (config.ENABLE_DISCORD_INTEGRATION && guilds && guilds.length > 0) {
      checkBotInServer();
    }
  }, [company.discord_server_id, guilds]);

  const checkBotInServer = async () => {
    if (company.discord_server_id !== null) {
      const result = await es.checkBotInServer(company.discord_server_id);
      setBotIsInServerResult(result);
    } else {
      setBotIsInServerResult(null);
    }
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        {globalContext.company && (
          <VTCMenu active={"/company/settings"} container={container}></VTCMenu>
        )}

        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <Breadcrumbs
                    breadcrumbs={[
                      {
                        to: "/vtchub",
                        title: container.localeManager.strings.vtcHub,
                      },
                      {
                        title: container.localeManager.strings.companySettings,
                      },
                    ]}
                  ></Breadcrumbs>
                  {company != null && (
                    <>
                      {company.company_type != null && (
                        <>
                          <Nav
                            className="nav-custom nav-line-tabs nav-line-tabs-2x fs-6 fw-bold mb-3"
                            tabs
                          >
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "main",
                                })}
                                onClick={() => {
                                  toggleTab("main");
                                }}
                              >
                                {container.localeManager.strings.mainSettings}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "social",
                                })}
                                onClick={() => {
                                  toggleTab("social");
                                }}
                              >
                                {container.localeManager.strings.social}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "information",
                                })}
                                onClick={() => {
                                  toggleTab("information");
                                }}
                              >
                                {container.localeManager.strings.information}
                              </NavLink>
                            </NavItem>
                            {company.id && (
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === "discord",
                                  })}
                                  onClick={() => {
                                    toggleTab("discord");
                                  }}
                                >
                                  {container.localeManager.strings.discord}
                                </NavLink>
                              </NavItem>
                            )}
                            {company.id &&
                              company.owner_id == globalContext.member.id && (
                                <NavItem>
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === "api",
                                    })}
                                    onClick={() => {
                                      toggleTab("api");
                                    }}
                                  >
                                    {container.localeManager.strings.api}
                                  </NavLink>
                                </NavItem>
                              )}
                            {company.id &&
                              company.owner_id == globalContext.member.id && (
                                <>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        "text-danger": true,
                                        active: activeTab === "transfer",
                                      })}
                                      onClick={() => {
                                        toggleTab("transfer");
                                      }}
                                    >
                                      {
                                        container.localeManager.strings
                                          .transferOwnership
                                      }
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        "text-danger": true,
                                        active: activeTab === "danger",
                                      })}
                                      onClick={() => {
                                        toggleTab("danger");
                                      }}
                                    >
                                      {
                                        container.localeManager.strings
                                          .dangerZone
                                      }
                                    </NavLink>
                                  </NavItem>
                                </>
                              )}
                          </Nav>
                        </>
                      )}
                      <TabContent className="mt-3" activeTab={activeTab}>
                        <TabPane tabId="main">
                          <FormGroup>
                            {firstCompanyConfiguration && (
                              <Label>
                                {
                                  container.localeManager.strings
                                    .chooseCompanyStyle2
                                }
                              </Label>
                            )}
                            <div>
                              <ButtonGroup>
                                <Button
                                  onClick={() =>
                                    setData({
                                      ...company,
                                      company_type: "realistic",
                                    })
                                  }
                                  color={
                                    company.company_type == "realistic"
                                      ? "success"
                                      : "secondary"
                                  }
                                >
                                  {
                                    container.localeManager.strings
                                      .realisticEconomy
                                  }
                                </Button>
                                <Button
                                  onClick={() =>
                                    setData({
                                      ...company,
                                      company_type: "miles",
                                    })
                                  }
                                  color={
                                    company.company_type == "miles"
                                      ? "success"
                                      : "secondary"
                                  }
                                >
                                  {
                                    container.localeManager.strings
                                      .enterpriseMiles
                                  }
                                </Button>
                                <Button
                                  onClick={() =>
                                    setData({
                                      ...company,
                                      company_type: "both",
                                    })
                                  }
                                  color={
                                    company.company_type == "both"
                                      ? "success"
                                      : "secondary"
                                  }
                                >
                                  {container.localeManager.strings.ecoAndMiles}
                                </Button>
                              </ButtonGroup>
                            </div>
                            <div className="notice bg-light-success rounded border-success border border-dashed p-6 mb-3 mt-3">
                              {firstCompanyConfiguration && (
                                <>
                                  <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                                  {
                                    container.localeManager.strings
                                      .chooseCompanyStyleHeader
                                  }
                                </>
                              )}
                              <ul className="list-unstyled">
                                <li>
                                  <b>
                                    {
                                      container.localeManager.strings
                                        .realisticEconomy
                                    }
                                  </b>
                                  :{" "}
                                  {
                                    container.localeManager.strings
                                      .realisticEconomyDescription
                                  }
                                </li>
                                <li>
                                  <b>
                                    {
                                      container.localeManager.strings
                                        .enterpriseMiles
                                    }
                                  </b>
                                  :{" "}
                                  {
                                    container.localeManager.strings
                                      .enterpriseMilesDescription
                                  }
                                </li>
                                <li>
                                  <b>
                                    {
                                      container.localeManager.strings
                                        .ecoAndMiles
                                    }
                                  </b>
                                  :{" "}
                                  {
                                    container.localeManager.strings
                                      .ecoAndMilesDescription
                                  }
                                </li>
                              </ul>
                            </div>
                          </FormGroup>
                          {company.company_type != null && (
                            <>
                              <FormGroup row>
                                <Col sm={6}>
                                  <Label>
                                    {
                                      container.localeManager.strings
                                        .companyName
                                    }{" "}
                                    *
                                  </Label>
                                  <Input
                                    type="text"
                                    onChange={(e) =>
                                      setData({
                                        ...company,
                                        name: e.target.value,
                                      })
                                    }
                                    value={company.name}
                                  ></Input>
                                </Col>
                                <Col sm={6}>
                                  <Label>
                                    {container.localeManager.strings.tag} *
                                  </Label>
                                  <Input
                                    type="text"
                                    onChange={(e) =>
                                      setData({
                                        ...company,
                                        tag: e.target.value,
                                      })
                                    }
                                    value={company.tag}
                                  ></Input>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col sm={6}>
                                  <Label>
                                    {container.localeManager.strings.slogan} *
                                  </Label>
                                  <Input
                                    type="text"
                                    onChange={(e) =>
                                      setData({
                                        ...company,
                                        slogan: e.target.value,
                                      })
                                    }
                                    value={company.slogan}
                                  ></Input>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col sm={6}>
                                  <Label>
                                    {container.localeManager.strings.logo} *
                                  </Label>
                                  <Input
                                    type="file"
                                    onChange={(e) => setLogo(e.target.files[0])}
                                  ></Input>
                                </Col>
                                <Col sm={6}>
                                  <Label>
                                    {container.localeManager.strings.cover}
                                  </Label>
                                  <Input
                                    type="file"
                                    onChange={(e) =>
                                      setBanner(e.target.files[0])
                                    }
                                  ></Input>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col sm={6}>
                                  <Label>
                                    {container.localeManager.strings.country}
                                  </Label>
                                  <Select
                                    onChange={(val) =>
                                      setData({
                                        ...company,
                                        country_code: val.code,
                                      })
                                    }
                                    value={countries.find(
                                      (m) => m.code == company.country_code
                                    )}
                                    options={countries}
                                    styles={reactSelectCustomStyles}
                                    getOptionLabel={(m) => m.name}
                                    getOptionValue={(m) => m.code}
                                  ></Select>
                                </Col>
                                <Col sm={6}>
                                  <Label>
                                    {
                                      container.localeManager.strings
                                        .settingsHeaderLanguage
                                    }
                                  </Label>
                                  <Select
                                    onChange={(val) =>
                                      setData({
                                        ...company,
                                        language: val.name,
                                      })
                                    }
                                    value={languages.find(
                                      (m) => m.name == company.language
                                    )}
                                    options={languages}
                                    styles={reactSelectCustomStyles}
                                    getOptionValue={(m) => m.code}
                                    getOptionLabel={(m) => m.name}
                                  ></Select>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Col sm={6}>
                                  <Label>
                                    {
                                      container.localeManager.strings
                                        .recruitment
                                    }
                                  </Label>
                                  <Input
                                    type="select"
                                    value={company.recruitment}
                                    onChange={(e) =>
                                      setData({
                                        ...company,
                                        recruitment: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="">
                                      {
                                        container.localeManager.strings
                                          .notDefined
                                      }
                                    </option>
                                    <option value="open">
                                      {container.localeManager.strings.open}
                                    </option>
                                    <option value="closed">
                                      {container.localeManager.strings.closed}
                                    </option>
                                  </Input>
                                </Col>
                                <Col sm={6}>
                                  <Label>
                                    {
                                      container.localeManager.strings
                                        .inactiveMemberTreshold
                                    }
                                  </Label>
                                  <Input
                                    type="number"
                                    min="0"
                                    value={company.inactive_member_treshold}
                                    onChange={(e) =>
                                      setData({
                                        ...company,
                                        inactive_member_treshold:
                                          e.target.value,
                                      })
                                    }
                                  />
                                  <FormText color="muted">
                                    {
                                      container.localeManager.strings
                                        .inactiveMemberTresholdHint
                                    }
                                  </FormText>
                                </Col>
                              </FormGroup>
                              {(company.company_type == "realistic" ||
                                company.company_type == "both") && (
                                <>
                                  <FormGroup row>
                                    <Col sm={6}>
                                      <Label>
                                        {
                                          container.localeManager.strings
                                            .salaryType
                                        }
                                      </Label>
                                      <Input
                                        type="select"
                                        value={company.salary_type}
                                        onChange={(e) =>
                                          setData({
                                            ...company,
                                            salary_type: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="none">
                                          {container.localeManager.strings.none}
                                        </option>
                                        <option value="fixed">
                                          {
                                            container.localeManager.strings
                                              .fixed
                                          }
                                        </option>
                                        <option value="revenue_percentage">
                                          {
                                            container.localeManager.strings
                                              .revenue_percentage
                                          }
                                        </option>
                                        <option value="revenue_per_km">
                                          {
                                            container.localeManager.strings
                                              .revenue_per_km
                                          }
                                        </option>
                                      </Input>
                                      <FormText color="muted">
                                        {
                                          container.localeManager.strings
                                            .salariesExplain
                                        }
                                      </FormText>
                                    </Col>
                                    {company.salary_type != "none" && (
                                      <Col sm={6}>
                                        <Label>
                                          {
                                            container.localeManager.strings
                                              .baseMemberSalary
                                          }
                                        </Label>
                                        <InputGroup>
                                          <Input
                                            type="number"
                                            min="0"
                                            value={company.base_member_salary}
                                            onChange={(e) =>
                                              setData({
                                                ...company,
                                                base_member_salary:
                                                  e.target.value,
                                              })
                                            }
                                          />
                                          <InputGroupText>
                                            {company.salary_type ==
                                            "revenue_percentage"
                                              ? "%"
                                              : "T¢"}
                                          </InputGroupText>
                                        </InputGroup>
                                        <FormText color="muted">
                                          {
                                            container.localeManager.strings
                                              .salaryDescription
                                          }
                                        </FormText>
                                      </Col>
                                    )}
                                  </FormGroup>
                                  <FormGroup row>
                                    <Col sm={6}>
                                      <Label>
                                        {
                                          container.localeManager.strings
                                            .jobCancelationPenalty
                                        }
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          type="number"
                                          min="0"
                                          value={
                                            company.job_cancelation_penalty
                                          }
                                          onChange={(e) =>
                                            setData({
                                              ...company,
                                              job_cancelation_penalty:
                                                e.target.value,
                                            })
                                          }
                                        />
                                        <Button
                                          onClick={() =>
                                            setData({
                                              ...company,
                                              job_cancelation_penalty: 0,
                                            })
                                          }
                                        >
                                          <FontAwesomeIcon
                                            className="mx-0"
                                            icon="times"
                                          ></FontAwesomeIcon>
                                        </Button>
                                        <InputGroupText>T¢</InputGroupText>
                                      </InputGroup>
                                      <FormText color="muted">
                                        {
                                          container.localeManager.strings
                                            .jobCancelationPenaltyInfo
                                        }
                                      </FormText>
                                    </Col>
                                  </FormGroup>
                                </>
                              )}
                              {(company.company_type == "realistic" ||
                                company.company_type == "both") && (
                                <>
                                  <FormGroup row>
                                    <Col sm={6}>
                                      <Label>
                                        {
                                          container.localeManager.strings
                                            .headquarter
                                        }{" "}
                                        *
                                      </Label>
                                      {company.headquarter == null && (
                                        <AsyncSelect
                                          noOptionsMessage={({ inputValue }) =>
                                            !inputValue
                                              ? container.localeManager.strings
                                                  .startTyping
                                              : container.localeManager.strings
                                                  .noResults
                                          }
                                          onChange={(selected) =>
                                            onSelectHeadquarter(selected)
                                          }
                                          cacheOptions
                                          getOptionValue={(m) => m.id}
                                          getOptionLabel={(m) =>
                                            `${m.country.name} - ${m.name}`
                                          }
                                          styles={reactSelectCustomStyles}
                                          loadOptions={searchCities}
                                        ></AsyncSelect>
                                      )}
                                      {company.headquarter != null && (
                                        <Input
                                          type="text"
                                          disabled={true}
                                          value={company.headquarter.name}
                                        ></Input>
                                      )}
                                    </Col>
                                  </FormGroup>
                                  {firstCompanyConfiguration && (
                                    <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-3">
                                      <FontAwesomeIcon icon="info-circle" />
                                      Select your Headquarter, you can select
                                      from more than 2000 cities in the world
                                    </div>
                                  )}
                                </>
                              )}
                              {(company.company_type == "miles" ||
                                company.company_type == "both") && (
                                <FormGroup row>
                                  <Col
                                    sm={6}
                                    className="d-flex align-items-baseline col-sm-6 flex-column"
                                  >
                                    <label class="form-check form-switch form-check-custom form-check-solid">
                                      <input
                                        onChange={(e) =>
                                          setData({
                                            ...company,
                                            disable_cooldown: e.target.checked,
                                          })
                                        }
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={company.disable_cooldown}
                                      />
                                      <span class="form-check-label">
                                        {
                                          container.localeManager.strings
                                            .disableCooldown
                                        }
                                      </span>
                                    </label>
                                    <div className="form-text text-muted mt-3">
                                      {
                                        container.localeManager.strings
                                          .disableCooldownInfo
                                      }
                                    </div>
                                  </Col>
                                </FormGroup>
                              )}
                            </>
                          )}
                        </TabPane>
                        <TabPane tabId="information">
                          <FormGroup>
                            <Label>
                              {container.localeManager.strings.about}
                            </Label>
                            <MDEditor
                              data-color-mode="dark"
                              height={300}
                              value={company.about}
                              onChange={(value) =>
                                setData({ ...company, about: value })
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              {container.localeManager.strings.requirements}
                            </Label>
                            <MDEditor
                              data-color-mode="dark"
                              height={300}
                              value={company.requirements}
                              onChange={(value) =>
                                setData({ ...company, requirements: value })
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              {container.localeManager.strings.rules}
                            </Label>
                            <MDEditor
                              data-color-mode="dark"
                              height={300}
                              value={company.rules}
                              onChange={(value) =>
                                setData({ ...company, rules: value })
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              {
                                container.localeManager.strings
                                  .applicationRequirements
                              }
                            </Label>
                            <MDEditor
                              data-color-mode="dark"
                              height={200}
                              value={company.application_requirements}
                              onChange={(value) =>
                                setData({
                                  ...company,
                                  application_requirements: value,
                                })
                              }
                            />
                            <FormText className="muted">
                              {
                                container.localeManager.strings
                                  .informationRequirementsHelpText
                              }
                            </FormText>
                          </FormGroup>
                        </TabPane>
                        {activeTab == "discord" && (
                          <TabPane tabId="discord">
                            <h5>
                              {
                                container.localeManager.strings
                                  .companyWideRichPresence
                              }
                            </h5>
                            <FormGroup row>
                              <Col sm={6}>
                                <Label>
                                  {
                                    container.localeManager.strings
                                      .ets2ApplicationID
                                  }
                                </Label>
                                <Input
                                  type="text"
                                  value={company.rpc_app_id_ets2}
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      rpc_app_id_ets2: e.target.value,
                                    })
                                  }
                                ></Input>
                              </Col>
                              <Col sm={6}>
                                <Label>
                                  {
                                    container.localeManager.strings
                                      .atsApplicationID
                                  }
                                </Label>
                                <Input
                                  type="text"
                                  value={company.rpc_app_id_ats}
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      rpc_app_id_ats: e.target.value,
                                    })
                                  }
                                ></Input>
                              </Col>
                            </FormGroup>
                            <Row className="my-3">
                              <Col>
                                <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                                {container.localeManager.strings.companyRPCInfo}
                                <br />
                                <a
                                  onClick={() =>
                                    truckyService.openUrlInDefaultBrowser(
                                      "https://truckyapp.com/kb/how-to-change-vt-rpc-icons/"
                                    )
                                  }
                                >
                                  {
                                    container.localeManager.strings
                                      .customRPCHelp
                                  }
                                </a>
                              </Col>
                            </Row>
                            <Separator></Separator>
                            {config.ENABLE_DISCORD_INTEGRATION && guilds && (
                              <FormGroup row>
                                <Col>
                                  <Label>Discord Server</Label>
                                  <div className="d-flex align-items-center">
                                    <Input
                                      type="select"
                                      className="w-400px"
                                      value={company.discord_server_id}
                                      onChange={(e) =>
                                        setData({
                                          ...company,
                                          discord_server_id: e.target.value,
                                        })
                                      }
                                    >
                                      <option value={null}></option>
                                      {guilds.map((m) => {
                                        return (
                                          <option value={m.id}>{m.name}</option>
                                        );
                                      })}
                                    </Input>
                                    {botIsInserverResult &&
                                      botIsInserverResult.success && (
                                        <span className="text-success ms-3">
                                          TruckyBot has joined this Guild
                                        </span>
                                      )}
                                    {botIsInserverResult &&
                                      !botIsInserverResult.success && (
                                        <>
                                          <span className="text-danger ms-3">
                                            {botIsInserverResult.message}
                                          </span>
                                          <Button
                                            href="https://truckyapp.com/truckybot"
                                            target="_blank"
                                            color="primary"
                                            size="sm"
                                            className="ms-3"
                                          >
                                            Invite TruckyBot to your Server
                                          </Button>
                                        </>
                                      )}
                                  </div>
                                  <div className="text-warning mt-3">
                                    <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>{" "}
                                    If Discord Servers list doesn't populate
                                    correctly, try to refresh your Discord
                                    connection in Account Setting - Discord Tab
                                  </div>
                                </Col>
                              </FormGroup>
                            )}
                            <Separator></Separator>
                            <h5>
                              {
                                container.localeManager.strings
                                  .generalWebhooksConfiguration
                              }
                            </h5>
                            <FormGroup row className="mt-5">
                              <Col sm={6}>
                                <Label>
                                  {container.localeManager.strings.webhookMode}
                                </Label>
                                <Input
                                  type="select"
                                  value={company.webhook_mode}
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      webhook_mode: e.target.value,
                                    })
                                  }
                                >
                                  <option value="detailed">
                                    {container.localeManager.strings.detailed}
                                  </option>
                                  <option value="compact">
                                    {container.localeManager.strings.compact}
                                  </option>
                                </Input>
                              </Col>
                            </FormGroup>
                            <Separator></Separator>
                            <h5 className="mt-5">
                              {
                                container.localeManager.strings
                                  .unitsOnDiscordWebhookPerGame
                              }
                            </h5>
                            <h6 className="mt-3">Euro Truck Simulator 2</h6>
                            <FormText muted className="mb-3">
                              {container.localeManager.strings.gameStatsInfo}
                            </FormText>
                            <FormGroup row>
                              <Col sm={3}>
                                <Label>
                                  {container.localeManager.strings.distance}
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      webhook_ets2_distance_unit:
                                        e.target.value,
                                    })
                                  }
                                  value={company.webhook_ets2_distance_unit}
                                >
                                  <option value="km">
                                    {container.localeManager.strings.kilometers}
                                  </option>
                                  <option value="mi">
                                    {container.localeManager.strings.miles}
                                  </option>
                                </Input>
                              </Col>
                              <Col sm={3}>
                                <Label>
                                  {container.localeManager.strings.weightUnit}
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      webhook_ets2_weight_unit: e.target.value,
                                    })
                                  }
                                  value={company.webhook_ets2_weight_unit}
                                >
                                  <option value="t">
                                    {container.localeManager.strings.tons}
                                  </option>
                                  <option value="lb">
                                    {container.localeManager.strings.libres}
                                  </option>
                                </Input>
                              </Col>
                              <Col sm={3}>
                                <Label>
                                  {container.localeManager.strings.volumeUnit}
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      webhook_ets2_volume_unit: e.target.value,
                                    })
                                  }
                                  value={company.webhook_ets2_volume_unit}
                                >
                                  <option value="l">
                                    {container.localeManager.strings.litres}
                                  </option>
                                  <option value="gal">
                                    {container.localeManager.strings.usGallons}
                                  </option>
                                </Input>
                              </Col>
                            </FormGroup>

                            <h6 className="mt-3">American Truck Simulator</h6>
                            <FormText muted className="mb-3">
                              {container.localeManager.strings.gameStatsInfo}
                            </FormText>
                            <FormGroup row>
                              <Col sm={3}>
                                <Label>
                                  {container.localeManager.strings.distance}
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      webhook_ats_distance_unit: e.target.value,
                                    })
                                  }
                                  value={company.webhook_ats_distance_unit}
                                >
                                  <option value="km">
                                    {container.localeManager.strings.kilometers}
                                  </option>
                                  <option value="mi">
                                    {container.localeManager.strings.miles}
                                  </option>
                                </Input>
                              </Col>
                              <Col sm={3}>
                                <Label>
                                  {container.localeManager.strings.weightUnit}
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      webhook_ats_weight_unit: e.target.value,
                                    })
                                  }
                                  value={company.webhook_ats_weight_unit}
                                >
                                  <option value="t">
                                    {container.localeManager.strings.tons}
                                  </option>
                                  <option value="lb">
                                    {container.localeManager.strings.libres}
                                  </option>
                                </Input>
                              </Col>
                              <Col sm={3}>
                                <Label>
                                  {container.localeManager.strings.volumeUnit}
                                </Label>
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    setData({
                                      ...company,
                                      webhook_ats_volume_unit: e.target.value,
                                    })
                                  }
                                  value={company.webhook_ats_volume_unit}
                                >
                                  <option value="l">
                                    {container.localeManager.strings.litres}
                                  </option>
                                  <option value="gal">
                                    {container.localeManager.strings.usGallons}
                                  </option>
                                </Input>
                              </Col>
                            </FormGroup>
                            {getSaveButton()}
                            <Separator></Separator>
                            <h5 className="mt-5">
                              {container.localeManager.strings.webhooks}
                            </h5>
                            <div className="mb-3">
                              <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                              <a
                                onClick={() =>
                                  truckyService.openUrlInDefaultBrowser(
                                    "https://truckyapp.com/kb/vtc-hub-discord-integration/"
                                  )
                                }
                              >
                                {
                                  container.localeManager.strings
                                    .discordWebhooksHelp
                                }
                              </a>
                            </div>
                            <CompanyWebhooks
                              company={company}
                              container={container}
                              platform="discord"
                              reloadCompany={getCompany}
                            ></CompanyWebhooks>
                          </TabPane>
                        )}
                        {company.owner_id == globalContext.member.id && (
                          <>
                            <TabPane tabId="api">
                              <div className="my-3">
                                {container.localeManager.strings.yourCompanyID}:{" "}
                                <strong>{company.id}</strong>
                              </div>
                              <FormGroup row>
                                {token == "" && (
                                  <Col>
                                    <Button
                                      onClick={claimToken}
                                      color="secondary"
                                    >
                                      {
                                        container.localeManager.strings
                                          .claimToken
                                      }
                                    </Button>
                                  </Col>
                                )}
                                {token != "" && (
                                  <Col>
                                    <InputGroup>
                                      <Input disabled value={token} />
                                      <Button
                                        color="secondary"
                                        title={
                                          container.localeManager.strings.copy
                                        }
                                        onClick={() =>
                                          overwolf.utils.placeOnClipboard(token)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className="m-0"
                                          icon="copy"
                                        ></FontAwesomeIcon>
                                      </Button>
                                    </InputGroup>
                                  </Col>
                                )}
                              </FormGroup>
                              <Row className="mb-3">
                                <Col>
                                  <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                                  <a
                                    onClick={() =>
                                      truckyService.openUrlInDefaultBrowser(
                                        "https://truckyapp.com/kb/vtc-hub-api-and-external-drivers-hub-integration/"
                                      )
                                    }
                                  >
                                    {
                                      container.localeManager.strings
                                        .apiHelpText
                                    }
                                  </a>
                                </Col>
                              </Row>
                              <h5 className="mt-3">
                                {container.localeManager.strings.webhooks}
                              </h5>
                              {activeTab == "api" && (
                                <CompanyWebhooks
                                  company={company}
                                  container={container}
                                  platform="api"
                                ></CompanyWebhooks>
                              )}
                            </TabPane>
                          </>
                        )}
                        <TabPane tabId="social">
                          <FormGroup row>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.discord}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setData({
                                    ...company,
                                    discord: e.target.value,
                                  })
                                }
                                value={company.discord}
                              ></Input>
                            </Col>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.twitter}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setData({
                                    ...company,
                                    twitter: e.target.value,
                                  })
                                }
                                value={company.twitter}
                              ></Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.twitch}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setData({
                                    ...company,
                                    twitch: e.target.value,
                                  })
                                }
                                value={company.twitch}
                              ></Input>
                            </Col>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.youtube}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setData({
                                    ...company,
                                    youtube: e.target.value,
                                  })
                                }
                                value={company.youtube}
                              ></Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.website}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setData({
                                    ...company,
                                    website: e.target.value,
                                  })
                                }
                                value={company.website}
                              ></Input>
                            </Col>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.contactEmail}
                              </Label>
                              <Input
                                type="text"
                                onChange={(e) =>
                                  setData({
                                    ...company,
                                    contact_email: e.target.value,
                                  })
                                }
                                value={company.contact_email}
                              ></Input>
                            </Col>
                          </FormGroup>
                        </TabPane>
                        <TabPane tabId="transfer">
                          <div className="notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3 mt-3">
                            {
                              container.localeManager.strings
                                .transferOwnershipDisclaimer
                            }
                          </div>
                          <FormGroup row>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.selectNewOner}
                              </Label>
                              <Input
                                type="select"
                                value={newOwner.new_owner_id}
                                onChange={(e) =>
                                  setNewOwner({
                                    ...newOwner,
                                    new_owner_id: e.target.value,
                                  })
                                }
                              >
                                <option value=""></option>
                                {members.map((m) => {
                                  return <option value={m.id}>{m.name}</option>;
                                })}
                              </Input>
                            </Col>
                            <Col sm={6}>
                              <Label>
                                {container.localeManager.strings.yourNewRole}
                              </Label>
                              <Input
                                type="select"
                                value={newOwner.new_role_id}
                                onChange={(e) =>
                                  setNewOwner({
                                    ...newOwner,
                                    new_role_id: e.target.value,
                                  })
                                }
                              >
                                <option value=""></option>
                                {roles.map((m) => {
                                  return <option value={m.id}>{m.name}</option>;
                                })}
                              </Input>
                            </Col>
                          </FormGroup>
                          {newOwner.new_owner_id != "" &&
                            newOwner.new_role_id != "" && (
                              <FormGroup>
                                <Button
                                  color="danger"
                                  onClick={transferOwnership}
                                >
                                  {
                                    container.localeManager.strings
                                      .transferOwnership
                                  }
                                </Button>
                              </FormGroup>
                            )}
                        </TabPane>
                        <TabPane tabId="danger">
                          <div className="mb-5 text-center">
                            <div className="notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3 mt-3">
                              <FontAwesomeIcon icon="triangle-exclamation"></FontAwesomeIcon>
                              {
                                container.localeManager.strings
                                  .resetCompanyDisclaimer
                              }
                            </div>
                            <Button color="danger" onClick={resetCompany}>
                              <FontAwesomeIcon icon="refresh"></FontAwesomeIcon>
                              {container.localeManager.strings.resetCompany}
                            </Button>
                          </div>
                          <div className="text-center">
                            <div className="notice bg-light-warning rounded border-warning border border-dashed p-6 mb-3 mt-3">
                              <FontAwesomeIcon icon="warning"></FontAwesomeIcon>
                              {container.localeManager.strings.closeCompanyInfo}
                            </div>
                            <Button onClick={closeCompany} color="danger">
                              {container.localeManager.strings.closeCompany}
                            </Button>
                          </div>
                        </TabPane>
                      </TabContent>
                      {errors != null && <ErrorBox errors={errors}></ErrorBox>}
                      {company.company_type != undefined &&
                        company.company_type != null &&
                        activeTab != "api" &&
                        activeTab != "discord" &&
                        activeTab != "danger" &&
                        getSaveButton()}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              {company != null &&
                company.id > 0 &&
                (company.company_type == "realistic" ||
                  company.company_type == "both") &&
                company.salary_type != null &&
                company.salary_type != "none" && (
                  <Card>
                    <CardBody>
                      <Button
                        color="secondary"
                        onClick={simulateSalaries}
                        block
                      >
                        <FontAwesomeIcon icon="calculator"></FontAwesomeIcon>
                        {container.localeManager.strings.simulateSalaries}
                      </Button>
                      <div className="mt-3 text-muted">
                        <FontAwesomeIcon icon="info-circle"></FontAwesomeIcon>
                        {
                          container.localeManager.strings
                            .simulateSalariesDisclaimer
                        }
                      </div>
                    </CardBody>
                  </Card>
                )}
            </aside>
          </Row>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Col>
      </Row>
      {simulatedSalaries != null && (
        <TransactionDetails
          company={company}
          container={container}
          details={simulatedSalaries.details}
          onClose={() => setSimulatedSalaries(null)}
        ></TransactionDetails>
      )}
    </LoadingOverlay>
  );
};

export default EditCompany;
