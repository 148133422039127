import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function Breadcrumbs({ breadcrumbs }) {
  return (
    <div className="d-flex mb-3 align-items-center">
      {breadcrumbs.map((m, index) => {
        return (
          <>
            {index != breadcrumbs.length - 1 && (
              <>
                <Link to={m.to}>{m.title}</Link>

                <FontAwesomeIcon
                  icon="chevron-right"
                  className="mx-2"
                ></FontAwesomeIcon>
              </>
            )}
            {index == breadcrumbs.length - 1 && (
              <span className="text-white">{m.title}</span>
            )}
          </>
        );
      })}
    </div>
  );
}
