import { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import EnterpriseService from "../../common/services/enterprise-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import JobsTable from "./JobsTable";
import { EnterpriseContext } from "./EnterpriseContext";
import EnterpriseNavBar from "./EnterpriseNavBar";
import Paginator from "../ui/Paginator";
import Tips from "../Tips";
import tipsList from "../../common/constants/tipsList";
import AsyncSelect from "react-select/async";
import reactSelectCustomStyles from "../../common/plugins/reactSelectStyles";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import pluginService from "../../common/services/plugin-service";
import moment from "moment";
import truckyService from "../../common/services/trucky-service";
import { confirmWrapper } from "../ui/askConfirm";
import MilesTypeExplain from "./MilesTypeExplain";
import config from "../../config";
import BoxDynamicAd from "../../ads/BoxDynamicAd";
import VTCMenu from "../../web/components/VTCMenu";
import Breadcrumbs from "../../web/components/Breadcrumbs";
const classnames = require("classnames");

const Jobs = ({ eventBus, container, top = 999, title }) => {
  const { globalContext } = useContext(EnterpriseContext);

  const company = globalContext.company;
  const member = globalContext.member;

  const [loading, setLoading] = useState(false);
  const es = new EnterpriseService();
  const [jobs, setJobs] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, perPage: 25 });
  const [totalPages, setTotalPages] = useState(0);
  const [searchParameters, setSearchParameters] = useState(
    truckyService.loadSearchParameters("enterprise_jobs", {
      status: "completed",
      user_id: "",
      game_id: "",
      game_mode: "",
      dateFrom: null,
      dateTo: null,
      sortingField: "updated_at",
      sortingDirection: "desc",
      market: "",
      source_city: "",
      destination_city: "",
      valid_hardcore_simulation: "",
    })
  );
  const usernameSearchSelect = useRef();
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    truckyService.setDocumentTitle("Jobs");
  }, []);

  useEffect(() => {
    truckyService.saveSearchParameters("enterprise_jobs", searchParameters);
  }, [searchParameters]);

  useEffect(() => {
    getJobs();
  }, [
    pagination,
    searchParameters.sortingDirection,
    searchParameters.sortingField,
  ]);

  const getJobs = async () => {
    setLoading(true);
    if (company) {
      const result = await es.getJobs(company, 0, pagination, searchParameters);
      setJobs(result.data);
      setTotalPages(result.last_page);
    } else {
      const result = await es.getMemberJobs(member, 0, pagination);
      setJobs(result.data);
      setTotalPages(result.last_page);
    }
    setLoading(false);
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const searchUsers = async (term) => {
    if (term.length > 3) {
      const result = await es.getMembers(
        company,
        { page: 1, perPage: 9999 },
        { name: term }
      );
      return result.data;
    }
  };

  const changeDatesRange = (value) => {
    if (value == null)
      setSearchParameters({
        ...searchParameters,
        dateFrom: null,
        dateTo: null,
      });
    else
      setSearchParameters({
        ...searchParameters,
        dateFrom: value[0],
        dateTo: value[1],
      });
  };

  const exportJobs = async () => {
    setLoading(true);
    const downloadUrl = es.createDownloadJobsUrl(company, searchParameters);

    if (window.overwolf) {
      const downloader = await pluginService.getPlugin("Downloader");
      const fileName = `${
        overwolf.io.paths.localAppData
      }\\TruckyOverlay\\Exports\\jobs_${moment().format(
        "MMDDYYYY_HHmmss"
      )}.xlsx`;

      downloader.onDownloadComplete.addListener(() => {
        overwolf.utils.openWindowsExplorer(fileName, () => {});
        setLoading(false);
      });

      downloader.downloadFile(downloadUrl, fileName, null);
    } else {
      window.location.href = downloadUrl;
      setLoading(false);
    }
  };

  const changeSortingField = ({ field, direction }) => {
    setSearchParameters({
      ...searchParameters,
      sortingField: field,
      sortingDirection: direction,
    });
  };

  const selectUserId = (selected) => {
    setSearchParameters({
      ...searchParameters,
      user_id: selected == null ? null : selected.id,
    });
  };

  const deleteSelected = async () => {
    if (
      await confirmWrapper(container.localeManager.strings.deleteSelectedConf, {
        container,
      })
    ) {
      setLoading(true);
      for (const jobToDelete of selected) {
        await es.deleteJob(jobToDelete);
      }
      setLoading(false);
      getJobs();
    }
  };

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <Spinner
          color="primary"
          style={{ width: "4rem", height: "4rem" }}
        ></Spinner>
      }
    >
      <Row noGutters className="" id="enterpriseContainer">
        <VTCMenu active={"/jobs"} container={container}></VTCMenu>
        <Col>
          <Row noGutters>
            <Col>
              <Card className="h-100">
                <CardBody>
                  <Breadcrumbs
                    breadcrumbs={[
                      {
                        to: "/vtchub",
                        title: container.localeManager.strings.vtcHub,
                      },
                      {
                        title: container.localeManager.strings.jobs,
                      },
                    ]}
                  ></Breadcrumbs>
                  <h5 className="d-inline-block w-100 mb-3">
                    {container.localeManager.strings.jobs}
                    <div className="float-end">
                      <Button size="sm" onClick={getJobs} color="primary">
                        <FontAwesomeIcon icon="sync-alt"></FontAwesomeIcon>
                        {container.localeManager.strings.reload}
                      </Button>
                    </div>
                  </h5>
                  <FormGroup row className="mb-3">
                    <Col sm="auto">
                      <Label>{container.localeManager.strings.game}</Label>
                      <Input
                        value={searchParameters.game_id}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            game_id: e.target.value,
                          })
                        }
                        type="select"
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        <option value="1">Euro Truck Simulator 2</option>
                        <option value="2">American Truck Simulator</option>
                      </Input>
                    </Col>
                    <Col sm="auto">
                      <Label>{container.localeManager.strings.mode}</Label>
                      <Input
                        value={searchParameters.game_mode}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            game_mode: e.target.value,
                          })
                        }
                        type="select"
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        <option value="sp">
                          {container.localeManager.strings.singleplayer}
                        </option>
                        <option value="truckersmp">TruckersMP</option>
                      </Input>
                    </Col>
                    <Col>
                      <Label>{container.localeManager.strings.driver}</Label>
                      <AsyncSelect
                        ref={usernameSearchSelect}
                        backspaceRemovesValue={true}
                        isClearable
                        noOptionsMessage={({ inputValue }) =>
                          !inputValue
                            ? container.localeManager.strings.startTyping
                            : container.localeManager.strings.noResults
                        }
                        cacheOptions
                        onChange={selectUserId}
                        getOptionValue={(m) => m.id}
                        getOptionLabel={(m) => m.name}
                        styles={reactSelectCustomStyles}
                        loadOptions={searchUsers}
                      ></AsyncSelect>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-3">
                    <Col>
                      <Label>
                        {container.localeManager.strings.sourceCity}
                      </Label>
                      <Input
                        value={searchParameters.source_city}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            source_city: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col>
                      <Label>
                        {container.localeManager.strings.destinationCity}
                      </Label>
                      <Input
                        value={searchParameters.destination_city}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            destination_city: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm="auto">
                      <Label>
                        {container.localeManager.strings.datesRange}
                      </Label>
                      <div>
                        <DateRangePicker
                          value={[
                            searchParameters.dateFrom,
                            searchParameters.dateTo,
                          ]}
                          locale={container.localeManager.language}
                          calendarIcon={
                            <FontAwesomeIcon icon="calendar-alt"></FontAwesomeIcon>
                          }
                          clearIcon={
                            <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                          }
                          onChange={changeDatesRange}
                        ></DateRangePicker>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <Label>{container.localeManager.strings.market}</Label>
                      <Input
                        value={searchParameters.market}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            market: e.target.value,
                          })
                        }
                        type="select"
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        <option value="quick_job">
                          {container.localeManager.strings.quick_job}
                        </option>
                        <option value="freight_market">
                          {container.localeManager.strings.freight_market}
                        </option>
                        <option value="cargo_market">
                          {container.localeManager.strings.cargo_market}
                        </option>
                        <option value="external_contracts">
                          {container.localeManager.strings.external_contracts}
                        </option>
                        <option value="external_market">
                          {container.localeManager.strings.external_market}
                        </option>
                        <option value="transport_system">
                          {container.localeManager.strings.transport_system}
                        </option>
                      </Input>
                    </Col>
                    <Col sm="auto">
                      <Label>{container.localeManager.strings.status}</Label>
                      <Input
                        value={searchParameters.status}
                        onChange={(e) =>
                          setSearchParameters({
                            ...searchParameters,
                            status: e.target.value,
                          })
                        }
                        type="select"
                      >
                        <option value="">
                          {container.localeManager.strings.all}
                        </option>
                        <option value="completed">
                          {container.localeManager.strings.completed}
                        </option>
                        <option value="in_progress">
                          {container.localeManager.strings.in_progress}
                        </option>
                        <option value="canceled">
                          {container.localeManager.strings.canceled}
                        </option>
                      </Input>
                    </Col>
                    {config.ENABLE_PROFILE_TRACKING && (
                      <Col sm="auto">
                        <Label>
                          {
                            container.localeManager.strings
                              .validForHardcoreLeaderboards
                          }
                        </Label>
                        <Input
                          value={searchParameters.valid_hardcore_simulation}
                          onChange={(e) =>
                            setSearchParameters({
                              ...searchParameters,
                              valid_hardcore_simulation: e.target.value,
                            })
                          }
                          type="select"
                        >
                          <option value="">
                            {container.localeManager.strings.all}
                          </option>
                          <option value="true">
                            {container.localeManager.strings.yes}
                          </option>
                          <option value="false">
                            {container.localeManager.strings.no}
                          </option>
                        </Input>
                      </Col>
                    )}
                    <Col className="d-flex align-items-end mt-3 mt-md-0">
                      <Button size="sm" onClick={getJobs} color="primary">
                        <FontAwesomeIcon icon="search"></FontAwesomeIcon>
                        {container.localeManager.strings.search}
                      </Button>
                      <Button
                        size="sm"
                        className="ms-1"
                        onClick={exportJobs}
                        color="primary"
                      >
                        <FontAwesomeIcon icon="file-excel"></FontAwesomeIcon>
                        {container.localeManager.strings.export}
                      </Button>
                    </Col>
                  </FormGroup>
                  {!loading && (
                    <>
                      <div className="mt-3">
                        <Paginator
                          rowsPerPageSelected={pagination.perPage}
                          handleRowsPerPageChange={handleRowsPerPageChange}
                          showRowsPerPage={true}
                          forcePage={pagination.page - 1}
                          totalPages={totalPages}
                          handlePageClick={handlePageClick}
                        >
                          {es.can(member, "jobs.delete") && (
                            <Button
                              disabled={selected.length == 0}
                              onClick={deleteSelected}
                              color="danger"
                              size="sm"
                            >
                              <FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon>
                              {container.localeManager.strings.deleteSelected}
                            </Button>
                          )}
                        </Paginator>
                      </div>
                      <JobsTable
                        onSelectedChanged={setSelected}
                        checks={es.can(member, "jobs.delete")}
                        jobs={jobs}
                        container={container}
                        changeSortingField={changeSortingField}
                        filters={searchParameters}
                      ></JobsTable>
                      <div>
                        <Paginator
                          rowsPerPageSelected={pagination.perPage}
                          handleRowsPerPageChange={handleRowsPerPageChange}
                          showRowsPerPage={true}
                          forcePage={pagination.page - 1}
                          totalPages={totalPages}
                          handlePageClick={handlePageClick}
                        ></Paginator>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <aside className="ad-column-placeholder">
              <Tips
                tipKey={tipsList.KEYS.ENTERPRISE_JOBS}
                localeManager={container.localeManager}
              ></Tips>
              <MilesTypeExplain container={container}></MilesTypeExplain>
              {!window.overwolf && (
                <BoxDynamicAd container={container} className="my-3" />
              )}
            </aside>
          </Row>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default Jobs;
