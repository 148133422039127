import { Link } from "react-router-dom";
import Paginator from "../ui/Paginator";
import { useEffect, useState } from "react";
import EnterpriseService from "../../common/services/enterprise-service";
import { Col, Row } from "reactstrap";
import Loader from "../ui/Loader";

export default function CompanyMembers({ container, company }) {
  const [members, setMembers] = useState([]);
  const es = new EnterpriseService();
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, perPage: 45 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMembers();
  }, []);

  const handleRowsPerPageChange = (perPage) => {
    setPagination({ ...pagination, page: 1, perPage: perPage });
  };

  const handlePageClick = (event) => {
    setPagination({ ...pagination, page: event.selected + 1 });
  };

  const getColoredSpan = (member, value) => {
    if (member.role != null) {
      return <span style={{ color: member.role.color }}>{value}</span>;
    } else {
      return <span>{value}</span>;
    }
  };

  const getMembers = async () => {
    setLoading(true);
    const result = await es.getMembers(company, pagination);
    setMembers(result.data);
    setTotalPages(result.last_page);
    setLoading(false);
  };

  useEffect(() => {
    getMembers();
  }, [pagination.page, pagination.perPage]);

  return (
    <div>
      {loading && <Loader></Loader>}
      {!loading && (
        <div>
          <Paginator
            showRowsPerPage={true}
            forcePage={pagination.page - 1}
            totalPages={totalPages}
            handlePageClick={handlePageClick}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageSelected={pagination.perPage}
            rowsPerPage={[45, 60, 90, 120]}
          />
          <Row className="mb-3 mb-xl-9 gx-0 my-5">
            {members.map((m) => {
              return (
                <Col className="col-12 col-md-4 col-lg-4">
                  <div class="card">
                    <div class="card-body d-flex flex-center flex-column py-3 px-3">
                      <div class="symbol symbol-65px mb-5">
                        <img className="rounded" src={m.avatar_url} />
                      </div>
                      <Link
                        to={`/user/${m.id}`}
                        class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                      >
                        {m.name}
                      </Link>
                      <div class="fw-semibold text-gray-400 mb-6">
                        {m.role != null ? getColoredSpan(m, m.role.name) : ""}
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Paginator
            rowsPerPage={[45, 60, 90, 120]}
            forcePage={pagination.page - 1}
            totalPages={totalPages}
            handlePageClick={handlePageClick}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageSelected={pagination.perPage}
          />
        </div>
      )}
    </div>
  );
}
